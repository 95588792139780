@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap");

@layer base {
    * {
        font-family: "Montserrat", sans-serif;
    }

    body {
        @apply bg-light dark:bg-dark scroll overflow-y-scroll;
    }

    html {
        @apply text-primary;
    }

    table {
        @apply text-primary;
    }

    button {
        font-family: "Montserrat", sans-serif;
    }
}

.submit-button {
    @apply text-white font-semibold uppercase p-3 rounded w-[220px] h-[50px] flex justify-center items-center bg-gradient-to-b from-action to-actiongradient focus:bg-gradient-to-b focus:from-actiongradient focus:to-action;
}

.submit-button-disabled {
    @apply text-white font-semibold uppercase p-3 rounded w-[220px] h-[50px] flex justify-center items-center bg-gradient-to-b from-slate-400 to-slate-500;
}

/* MODAL */

.slide-modal {
    animation-duration: 0.1s;
    animation-name: slidein;
    animation-iteration-count: 1;
    animation-direction: normal;
}

@keyframes slidein {
    from {
        margin-top: -100%;
    }

    to {
        margin-top: 0%;
    }
}

.scrollbar {
    @apply overflow-auto;
}

/* total width */
.scrollbar::-webkit-scrollbar {
    width: 8px;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
    @apply my-10;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
    border: 1px solid #26263f;
    @apply rounded absolute -right-6;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
    display: none;
}

/* Global */
.scroll::-webkit-scrollbar {
    width: 3px;
}

/* Fond */
.scroll::-webkit-scrollbar-track {
    @apply bg-white dark:bg-dark;
}

/* Ascenseur */
.scroll::-webkit-scrollbar-thumb {
    @apply bg-dark/40 dark:bg-white/10;
    border-radius: 8px;
}

/* Boutons */
.scroll::-webkit-scrollbar-button:single-button {
    display: block;
    border-style: solid;
    height: 20px;
    width: 16px;
}

.thinscrollbar {
    scrollbar-color: gray transparent;
    scrollbar-width: thin;
}

section {
    @apply rounded flex flex-col gap-8 !p-6 dark:bg-gradient-page;
    @apply text-dark dark:text-white;
    @apply bg-dark/10 dark:bg-dark/60;
}

.title {
    @apply font-bold text-xl uppercase underline decoration-accent decoration-[6px] underline-offset-8;
    @apply text-dark dark:text-white;
}

.section-title {
    @apply font-bold text-xl uppercase underline decoration-accent decoration-[6px] underline-offset-8 w-full;
    @apply text-dark dark:text-white;
}

.subtitle {
    @apply text-accent text-xl mb-2 font-semibold;
}

.cards-container {
    @apply grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-8;
}

._card {
    @apply card rounded shadow-sm shadow-dark/40 dark:shadow-white/10 !p-4 relative;
    @apply text-dark dark:text-white;
    @apply bg-white dark:bg-dark/60;
}

.card-button {
    @apply py-5 flex gap-5 items-center font-bold uppercase text-white xl:col-span-5;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="url"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="color"],
input[type="tel"],
select,
select[multiple],
textarea {
    @apply bg-light dark:bg-dark border border-neutral text-dark dark:text-white h-[50px] !rounded !p-3 !mt-2 w-full leading-tight focus:outline focus:outline-accent;
}


input[type="checkbox"] {
    @apply bg-light dark:bg-dark border border-neutral text-dark dark:text-white !rounded !p-3 leading-tight focus:outline focus:outline-accent;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
