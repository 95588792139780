.filepond--credits {
    display: none;
}

.filepond--drop-label {
    @apply bg-action min-h-screen rounded-md;
}

/*
.filepond--wrapper {
    @apply absolute md:relative -top-[15px];
}
*/

.filepond--label-action {
    @apply p-3 w-12 h-12;
}
